import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {EtablissementService} from "../../services/admin/etablissements/etablissement.service";
import {AuthService} from "../service/auth.service";

@Injectable({
  providedIn: 'root'
})
export class CheckCurrentEtablissementGuard implements CanActivate {

  constructor(private router: Router, private etablissementService:EtablissementService, private authService:AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {

      if(this.etablissementService.currentEtablissmentValue){
          return true;
      }
      let user = this.authService.currentUserValue;
      if (user.super_admin){
          this.router.navigate(['/home/etablissements']);
          return false;
      }
      let autsEtab = user['etablissements']?.filter(etab => etab.etat);
      if (autsEtab?.length == 1){
          this.etablissementService.setCurrentEtablissment(autsEtab[0]?.etablissement);
          return true;
      }
      else if (autsEtab?.length == 0){
          this.router.navigate(['/home/etablissements']);
          return false;
      }else{
          this.router.navigate(['/home/etablissements']);
          return false;
      }
  }
  
}
