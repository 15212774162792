import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import { environment } from "src/environments/environment";
import {Structure} from "../../../core/models/structure";
import {Etablissement} from "../../../home/etablissements/models/etablissement.model";

@Injectable({
  providedIn: 'root'
})
export class EtablissementService {
    constructor(private httpClient: HttpClient) { }

    //Get Liste des Etablissements

    public get currentEtablissmentValue(): Etablissement {
        if (localStorage.getItem("currentEtablissement") !== 'undefined'){
            return JSON.parse(localStorage.getItem("currentEtablissement"));
        }else {
            return null;
        }

    }

    setCurrentEtablissment(etablissement:any){
        localStorage.setItem("currentEtablissement", JSON.stringify(etablissement));
    }

    getEtablissements(){
        return this.httpClient.get(environment.apiUrl+"/admin/etablissements");
    }


    //Add Etablissement
    addEtablissement(data){
        return this.httpClient.post(environment.apiUrl+"/admin/etablissements",data);
    }

    updateEtablissement(data){
        return this.httpClient.put(environment.apiUrl+"/admin/etablissements/"+data['id'],data);
    }

}
