<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>
        <li class="sidebar-user-panel">
          <div class="user-panel">
            <div class=" image">
              <img src={{userImg}} class="img-circle user-img-circle" alt="User Image"/>
            </div>
          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name">{{userFullName}} </div>
            <div class="profile-usertitle-job ">{{userType}} </div>
          </div>
        </li>
        <!-- Top Most level menu -->
        <div class="header">{{currentStructure.nom|translate}}</div>
        <!-- Top Most level menu -->
        <li [class.active]="isActive('/admin/main')">
          <a (click)="goAccueil()" class="menu-top">
            <i-feather name="home" class="sidebarIcon"></i-feather>
            <span class="hide-menu">{{'Accueil Principal'|translate}}</span>
          </a>
        </li>
        <ng-container *ngFor="let sidebarItem of sidebarItems">
          <li *ngIf="checkPermission(sidebarItem.permission)" [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
              [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
            <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
               [ngClass]="[sidebarItem.class]" (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top">
              <i-feather *ngIf="sidebarItem.iconType == 'feather'" [name]="sidebarItem.icon" class="sidebarIcon"></i-feather>
              <span class="hide-menu">{{sidebarItem.title | translate}}</span>
              <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
            </a>
            <!-- First level menu -->
            <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
              <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''"
                  *ngFor="let sidebarSubItem of sidebarItem.submenu"
                  [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
                <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                   (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                  {{sidebarSubItem.title | translate}}
                </a>

                <!-- Second level menu -->
                <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                  <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                      [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                      [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                    <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                       (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                       [ngClass]="[sidebarSubsubItem.class]">
                      {{sidebarSubsubItem.title | translate}}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
        <li>
          <a (click)="logout()" class="menu-top">
            <i-feather name="log-out" class="sidebarIcon"></i-feather>
            <span class="hide-menu">{{'Déconnexion'|translate}}
            </span>
          </a>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
