import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatTabsModule } from "@angular/material/tabs";
import { HomeSidebarComponent } from './home-sidebar/home-sidebar.component';
import {FeatherModule} from "angular-feather";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
@NgModule({
    imports: [CommonModule, NgbModule, MatTabsModule, FeatherModule, PerfectScrollbarModule, TranslateModule, MatIconModule],
    declarations: [

        HomeSidebarComponent
    ],
    exports: [
        HomeSidebarComponent
    ]
})
export class LayoutModule {}
