import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Structure} from "../../core/models/structure";

@Injectable({
  providedIn: 'root'
})
export class StructureService {

  constructor(private httpClient: HttpClient) {
  }

  public get currentStructureValue(): Structure {
    return JSON.parse(localStorage.getItem("currentStructure"));
  }

  setCurrentStructure(structure:any){
    localStorage.setItem("currentStructure", JSON.stringify(structure));
  }

  getStructures(){
    return this.httpClient.get(environment.apiUrl+"/parametre/structures");
  }

  getStructuresSouscrites(){
    return this.httpClient.get(environment.apiUrl+"/parametre/get-structures-souscrites");
  }

  addStructure(data){
    console.log(data);
    return this.httpClient.post(environment.apiUrl+"/parametre/structures",data);
  }


  removeStructure(id) {
    return this.httpClient.delete(environment.apiUrl+"/parametre/structures/"+id);
  }
}
