import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
})
export class HomeLayoutComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
