import { Role } from "./role";

export class User {
  id: number;
  img: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: Role;
  token: string;
  etablissements: any;
  structures: any;
  super_admin: any;
  permissions: any;

  constructor(user) {
    this.img = user.img;
    this.username = user.username;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.role = user.role;
    this.token = user.token;
    this.etablissements = user.etablissements;
    this.structures = user.structures;
    this.super_admin = user.super_admin;
    this.permissions = user.permissions;
  }


}
