<div>
    <!-- Left Sidebar -->
    <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
        <!-- Menu -->
        <div class="menu">
            <ul class="list" [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}" [perfectScrollbar]>
                <li class="sidebar-user-panel">
                    <div class="user-panel">
                        <div class=" image">
                            <img src={{userImg}} class="img-circle user-img-circle" alt="User Image"/>
                        </div>
                    </div>
                    <div class="profile-usertitle">
                        <div class="sidebar-userpic-name">{{userFullName}}</div>
                        <div class="profile-usertitle-job ">{{userType|translate}}</div>
                    </div>
                </li>
                <div class="header">{{currentEtablissment?.nom|translate}}</div>
                <li [class.active]="isActive('/home/accueil')" class="mt-5">
                    <a (click)="goAccueil()" class="menu-top">
                        <i-feather name="home" class="sidebarIcon"></i-feather>
                        <span class="hide-menu">{{'Accueil Principal'|translate}}</span>
                    </a>
                </li>
                <li *ngIf="userType == 'Developpeur' && structureInfos.lycee" [class.active]="isActive('/home/annee-scolaires')">
                    <a (click)="goAnneeScolaire()" class="menu-top">
                        <i-feather name="pie-chart" class="sidebarIcon"></i-feather>
                        <span class="hide-menu">{{'Année Scolaires'|translate}}</span>
                    </a>
                </li>
                <li *ngIf="userType == 'Developpeur' && structureInfos.lycee">
                    <a (click)="goLycee()" class="menu-top">
                        <i-feather name="layers" class="sidebarIcon"></i-feather>
                        <span class="hide-menu">{{'Lycée'|translate}}</span>
                    </a>
                </li>
                <li *ngIf="userType == 'Developpeur' && structureInfos.college">
                    <a (click)="goCollege()" class="menu-top">
                        <i-feather name="layers" class="sidebarIcon"></i-feather>
                        <span class="hide-menu">{{'Collége'|translate}}</span>
                    </a>
                </li>
                <li *ngIf="userType == 'Developpeur' && structureInfos.primaire">
                    <a (click)="goPrimaire()" class="menu-top">
                        <i-feather name="layers" class="sidebarIcon"></i-feather>
                        <span class="hide-menu">{{'École Primaire'|translate}}</span>
                    </a>
                </li>
                <li *ngIf="userType == 'Developpeur' && structureInfos.prescolaire">
                    <a (click)="goPrescolaire()" class="menu-top">
                        <i-feather name="layers" class="sidebarIcon"></i-feather>
                        <span class="hide-menu">{{'Prescolaire'|translate}}</span>
                    </a>
                </li>
                <li *ngIf="userType == 'Developpeur' && structureInfos.primaire">
                    <a (click)="goDaara()" class="menu-top">
                        <i-feather name="layers" class="sidebarIcon"></i-feather>
                        <span class="hide-menu">{{'Daara'|translate}}</span>
                    </a>
                </li>
                <li [class.active]="isActive('/home/etablissements/list')">
                    <a (click)="goEtablissement()" class="menu-top">
                        <i-feather name="grid" class="sidebarIcon"></i-feather>
                        <span class="hide-menu">{{'Établissements'|translate}}</span>
                    </a>
                </li>
                <li *ngIf="userType == 'Developpeur'" [class.active]="isActive('/home/settings')">
                    <a (click)="goSettings()" class="menu-top">
                        <i-feather name="settings" class="sidebarIcon"></i-feather>
                        <span class="hide-menu">{{'Settings'|translate}}</span>
                    </a>
                </li>
                <li>
                    <a (click)="logout()" class="menu-top">
                        <i-feather name="log-out" class="sidebarIcon"></i-feather>
                        <span class="hide-menu">{{'Déconnexion'|translate}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <!-- #Menu -->
    </aside>
    <!-- #END# Left Sidebar -->
</div>
