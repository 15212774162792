import {Directive, ElementRef, Input} from '@angular/core';
import {AuthService} from "../service/auth.service";

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {

  @Input('appHasPermission') permission: string;  // La permission passée en paramètre

  constructor(private el: ElementRef, private authService: AuthService) {}

  ngOnInit() {
    const user = this.authService.currentUserValue;
    let permit = user.permissions.find(permission => permission.name === this.permission) !== undefined;
    console.log(permit);
    console.log(this.permission);
    // Si l'utilisateur n'a pas la permission, on masque l'élément
    if (this.permission && !permit && !user.super_admin) {
      this.el.nativeElement.style.display = 'none';
    }
  }

}
