import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "../service/auth.service";
import {EtablissementService} from "../../services/admin/etablissements/etablissement.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private etablissementService:EtablissementService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.currentUserValue;
    let etablissement_id = this.etablissementService.currentEtablissmentValue?.id;
    if (currentUser && currentUser.token) {
      let url = request.url.includes('?')
          ? `${request.url}&etablissement_id=${etablissement_id}`
          : `${request.url}?etablissement_id=${etablissement_id}`;
      request = request.clone({
        url: url,
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
    }
    return next.handle(request);
  }
}
